import store from "@/store";
import JsonBigInt from "json-bigint";

const Qs = require('qs');

export function useFinderReq (Vue, axios) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_FINDER_SETTING_API_URL,
    paramsSerializer: function (params) {
      return Qs.stringify(params);
    },
    transformResponse: data => {
      return JsonBigInt({storeAsString: true}).parse(data);
    }
  });

  instance.interceptors.request.use(config => {
    // pageNum 替换为 pageNo
    if (config.url.indexOf(process.env.VUE_APP_API_URL)>-1 && config.params && config.params.pageNum) {
      config.params.pageNo = config.params.pageNum;
      delete config.params.pageNum;
    }
    config.headers['Authorization'] = store.getters.token;
    return config;
  });
  instance.interceptors.response.use(function (res) {
    const { data } = res;

    if (res.config.url.indexOf('/') === 0) {
      return data;
    }
    if (data.code === 0) {
      return data.data;
    }

    const err = new Error(data.msg);
    err.res = res;
    throw new Error(res);
  }, (err) => {
    throw err;
  });

  Vue.prototype.$http = instance;

  return instance;
}
