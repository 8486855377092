import fetch from './req';
import axios from 'axios';

let source;

export function cancelFetch (msg) {
  source && source.cancel(msg);
}

export function getMenuList () {
  return fetch.get(`/auth/menu/list`);
}

/**
 * @param {Object} params
 * @param {String} params.name
 * @param {String} params.description
 * @param {Array} params.permissionIds
 */
export function createRole (params) {
  return fetch.post(`/role/create`, params, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

/**
 * @param {Object} params
 * @param {String} params.name
 * @param {String} params.description
 * @param {Array} params.permissionIds
 * @param {Number} params.id
 */
export function updateRole (params) {
  return fetch.post(`/role/update`, params, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

/**
 * @param {Number} id
 */
export function deleteRole (id) {
  return fetch.post(`/role/delete`, { id });
}

export function getRoleList (params) {
  return fetch.get(`/role/list`, { params });
}

export function getPermission () {
  source = axios.CancelToken.source();
  return fetch.get(`/permission/list/all`, { cancelToken: source.token });
}

/**
 * @param {Number} id
 * @param {Number} pageNum
 * @param {Number} pageSize
 */
export function getRoleDetail (params) {
  return fetch.get(`/role/getOne`, { params });
}