export default [
  {
    name: "Login",
    path: "/login",
    component: () => import("@/views/common/login")
  },
  {
    name: "Login",
    path: "/login/*",
    component: () => import("@/views/common/login")
  },
  {
    name: "LoginSuper",
    path: "/auth/super",
    component: () => import("@/views/common/login/admin")
  },
  {
    name: "LoginOperator",
    path: "/auth/operator",
    component: () => import("@/views/common/login/operator")
  }
];
