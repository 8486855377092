import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from "@/router/routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const whiteMenus = ['Login', 'LoginSuper', 'Notfound'];

router.beforeEach((to, from, next) => {
  if (whiteMenus.includes(to.name)) {
    return next();
  }
  if (!store.getters.token) {
    next({
      path: "/login"
    });
    return;
  }
  next();
});

export default router;
