import axios from 'axios';
import { resourceMenu } from '@/api/finder';

function convertTreeList (id, list) {
  const arr = [];
  for (const item of list) {
    if (item.resourceType === 'button') {
      item.disabled = true;
    }
    if (item.resourceParentId === id) {
      item.children = convertTreeList(item.resourceId, list);
      arr.push(item);
    }
  }
  return arr.length === 0 ? undefined : arr;
}

function menuGet (key){
  return JSON.parse(localStorage.getItem(key));
}

export default {
  namespaced: true,
  state: {
    menus: menuGet('account_menus')
  },
  mutations: {
    setMenus (state, payload) {
      state.menus = payload;
      localStorage.setItem('account_menus', JSON.stringify(payload));
    }
  },
  actions: {
    async getMenuList ({ commit }) {
      const {data} = await axios.get(resourceMenu);
      const arr = [];
      for (const item of data.data.list) {
        if (item.resourceParentId === 0) {
          item.children = convertTreeList(item.resourceId, data.data.list);
          arr.push(item);
        }
      }
      arr.push({
          resourceName: '连接配置',
          resourceIcon: 'icon-shujuku-01',
          menuPath: '/db'
        },
        {
          resourceName: '菜单配置',
          resourceIcon: 'icon-diantileimu',
          menuPath: '/menu'
        });
      commit('setMenus', arr);
    }
  }
};
