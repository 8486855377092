export default [
  {
    path: '/db',
    name: '连接配置',
    component: () => import('@/views/system/db')
  },
  {
    path: '/db_table',
    name: 'DBTable',
    component: () => import('@/views/system/db_table')
  },
  {
    path: '/db_table/field',
    name: 'DBTableField',
    component: () => import('@/views/system/db_table/field')
  },
  {
    path: '/swagger',
    name: 'Swagger',
    component: () => import('@/views/system/swagger')
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import('@/views/system/log')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/system/setting')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/views/system/menu')
  },
  {
    path: '/menu/detail',
    name: 'MenuDetail',
    component: () => import('@/views/system/menu/detail')
  },
  {
    path: '/table-setting',
    name: 'TableSetting',
    component: () => import('@/views/system/table-setting')
  },
  {
    path: '/table-setting/:id',
    name: 'TableSettingField',
    component: () => import('@/views/system/table-setting/field')
  },
  {
    path: '/login-policy',
    name: '登录策略',
    component: () => import('@/views/setting/login-policy')
  },
  {
    path: '/password-policy',
    name: '密码策略',
    component: () => import('@/views/setting/password-policy')
  },
  {
    path: '/mail-config',
    name: '邮箱配置',
    component: () => import('@/views/setting/mail-config')
  },
  {
    path: '/img-config',
    name: '图片配置',
    component: () => import('@/views/setting/img-config')
  },
  {
    path: '/company-confirm',
    name: '企业认证',
    component: () => import('@/views/setting/company-confirm')
  },
  {
    path: '/bus-integration',
    name: '业务集成',
    component: () => import('@/views/setting/bus-integration')
  },
  {
    path: '/bus-detail',
    name: '业务详情',
    component: () => import('@/views/setting/bus-detail')
  },
  {
    path: '/notice-manage',
    name: '公告管理',
    component: () => import('@/views/setting/notice-manage')
  },
  {
    path: '/notice-detail',
    name: '公告详情',
    component: () => import('@/views/setting/notice-detail')
  },
];
