/**
 * 用户信息
 */
export default {
  namespaced: true,

  state: {
    nickname: getUserInfo("nickname"),
    expirationTime: getUserInfo("expirationTime"),
    token: getUserInfo("token"),
    uid: getUserInfo("uid"),
    username: getUserInfo("username"),
  },

  mutations: {
    setUser (state, payload) {
      state.nickname = payload.nickname;
      state.expirationTime = payload.expirationTime;
      state.token = payload.token;
      state.uid = payload.uid;
      state.username = payload.username;
    }
  },

  actions: {
    setUserInfo ({ commit }, payload) {
      commit("setUser", payload);
      if (Object.keys(payload).length !== 0) {
        sessionStorage.setItem("USER_INFO", JSON.stringify(payload));
      } else {
        sessionStorage.removeItem("USER_INFO");
      }
    }
  }
};

function getUserInfo (key) {
  const user = {};
  try {
    const data = sessionStorage.getItem("USER_INFO");
    Object.assign(user, JSON.parse(data));
  } catch (e) {
    console.error("not user info");
  }
  return key ? user[key] : {};
}
