
import fetch from './req';

export function getSystemInfo () {
  const params = { tenantId: 'f7c4c29cf92641c0b816f2ed49c396c4' };
  return fetch({
    url: '/system/list',
    method: 'get',
    params: params
  });
}

export function updateSystemInfo (params) {
  return fetch({
    url: '/system/update',
    method: 'post',
    data: params
  });
}

// 获取企业信息
export function getEnterpInfo () {
  const params = { tenantId: 'f7c4c29cf92641c0b816f2ed49c396c4' };
  return fetch({
    url: '/tenant/getOne',
    method: 'post',
    data: params
  });
}

// 设置企业logo
export function setEnterpLogo (params) {
  return fetch({
    url: '/tenant/otherSet',
    method: 'post',
    data: params
  });
}

// 获取企业背景图
export function setEnterpBgimg (params) {
  return fetch({
    url: '/tenant/setup',
    method: 'post',
    data: params
  });
}

// 查询企业是否已认证
export function isCorpAuthentication () {
  return fetch({
    url: '/tenant/isCorpAuthentication',
    method: 'get',
  });
}

// 查询当前租户下可支持的应用
export function getAppList () {
  return fetch({
    url: '/tenant/integration/tab',
    method: 'get',
  });
}

/**
 * 同步全量信息
 * @param {String} params.appId
 */
export function syncInfo (type, params) {
  return fetch({
    url: `/sync/${type}/all`,
    method: 'post',
    data: params
  });
}

/**
 * 根据三方标识查询已绑定的信息
 * @param {String} params.appId
 * @param {String} params.sns
 */
export function syncDetail (params) {
  return fetch({
    url: `/tenant/integration/get`,
    method: 'get',
    params: params
  });
}

/**
 * 绑定租户集成信息
 */
export function bdIntegration (params) {
  return fetch({
    url: `/tenant/integration/binding`,
    method: 'post',
    data: params
  });
}