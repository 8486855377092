/**
 * 租户信息
 */
export default {
  namespaced: true,

  state: {
    bgPicture: getTenantInfo("bgPicture"),
    entLogo: getTenantInfo("entLogo"),
    entName: getTenantInfo("entName"),
    tenName: getTenantInfo("tenName"),
  },

  mutations: {
    setTenant (state, payload) {
      state.bgPicture = payload.bgPicture;
      state.entLogo = payload.entLogo;
      state.entName = payload.entName;
      state.tenName = payload.tenName;
    }
  },

  actions: {
    setTenantInfo ({ commit }, payload) {
      commit("setTenant", payload);
      if (Object.keys(payload).length !== 0) {
        sessionStorage.setItem("TENANT_INFO", JSON.stringify(payload));
      } else {
        sessionStorage.removeItem("TENANT_INFO");
      }
    }
  }
};

function getTenantInfo (key) {
  const user = {};
  try {
    const data = sessionStorage.getItem("TENANT_INFO");
    Object.assign(user, JSON.parse(data));
  } catch (e) {
    console.error("not tenant info");
  }
  return key ? user[key] : '';
}
