let domain = process.env.VUE_APP_API_URL;
const serviceDomain = process.env.VUE_APP_FINDER_SETTING_API_URL;

const schemaSetting = serviceDomain + '/finder/schema/setting';

const dbList = serviceDomain + '/ecsAdminConnection/finder/list';
const dbSetting = serviceDomain + '/ecsAdminConnection/finder/setting';
const addDb = '/ecsAdminConnection/add';
const updateDb = '/ecsAdminConnection/update';
const dbTableList = serviceDomain + '/ecsAdminSchema/finder/list';
const dbTableSetting = serviceDomain + '/ecsAdminSchema/finder/setting';
const addDbTable = '/ecsAdminSchema/add';
const updateDbTable = '/ecsAdminSchema/update';
const dbTableFieldList = serviceDomain + '/ecsAdminColumnSchema/finder/list';
const dbTableFieldSetting = serviceDomain + '/ecsAdminColumnSchema/finder/setting';
const menuList = serviceDomain + '/ecsAdminResource/finder/list';
const menuSetting = serviceDomain + '/ecsAdminResource/finder/setting';
const addMenu = serviceDomain + '/ecsAdminResource/add';
const updateMenu = serviceDomain + '/ecsAdminResource/update';
const deleteMenu = serviceDomain + '/ecsAdminResource/delete';

const swaggerUrl = 'http://ecboot.ex-sandbox.com/api/gateway/swagger-ui.html';
const resourceMenu = serviceDomain + '/ecsAdminResource/finder/list';
const dbTableList1 = serviceDomain + '/dbTool/schema/list';
const synchronizationTable = serviceDomain + '/dbTool/schema/synchronize';
const logList = domain + 'log/list';
const roleList = domain + 'role/list';
const userList = domain + 'user/list';
export {
  serviceDomain,
  dbList,
  dbSetting,
  addDb,
  updateDb,
  dbTableList,
  dbTableSetting,
  addDbTable,
  updateDbTable,
  dbTableFieldList,
  dbTableFieldSetting,
  schemaSetting,
  logList,
  menuList,
  menuSetting,
  addMenu,
  updateMenu,
  roleList,
  userList,
  swaggerUrl,
  resourceMenu,
  dbTableList1,
  synchronizationTable,
  deleteMenu,
};
