import Vue from 'vue';
import Element from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import { initFinder } from '@shopex/finder';
import '@shopex/finder/lib/finder.css';
import fetch from '@/api/req';
import './style/index.scss';
import { useFinderReq } from '@/api/finder-req';
import axios from 'axios';
Vue.config.productionTip = false;
Vue.use(Element);

initFinder(Vue, {
  fetchLibrary: fetch
});
useFinderReq(Vue, axios);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
