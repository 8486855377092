import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/main';
import System from "./modules/system";
import Login from "./modules/login";
import Permission from "./modules/permission";
Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: '我的应用',
    component: Main,
    redirect: "/application",
    children: [
      {
        path: '/application',
        name: 'Application',
        component: () => import('@/views/application')
      },
      {
        path: '/appmanage',
        name: 'Appmanage',
        component: () => import('@/views/appmanage')
      },
      {
        path: '/appmanage/add',
        name: 'AppmanageAdd',
        component: () => import('@/views/appmanage/add')
      },
      {
        path: '/reset/:id',
        name: 'ResetPassword',
        component: () => import('@/views/system/reset')
      },
      ...System,
      ...Permission,
    ]
  },
  ...Login,
  // 最后是404页面
  {
    path: '*',
    name: 'Notfound',
    component: () => import('@/views/common/notfound')
  }
];
