<template>
  <el-menu
    class="el-menu-wrap"
    background-color="#001529"
    text-color="#fff"
    router
    :default-active="this.$route.fullPath"
    active-text-color="#1890FF"
  >
    <SidebarSub v-for="menu in menus" :key="menu.resourceName" :index="menu.resourceName" :menu="menu" />
  </el-menu>
</template>

<script>
import SidebarSub from './sidebar-sub';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Sidebar',
  components: {
    SidebarSub,
  },
  data () {
    return {};
  },
  computed: {
    ...mapState({
      menus: state => state.menu.menus,
    }),
  },
  mounted () {
    this.getMenuList();
  },
  methods: {
    ...mapActions({
      getMenuList: 'menu/getMenuList'
    })
  }
};
</script>

<style lang="scss">
.el-menu-wrap {
  border-right-width: 0 !important;
  > div {
    > .el-menu-item {
      padding-left: 16px !important;
      padding-right: 16px;
      height: 40px;
      line-height: 40px;
      color: #eee !important;
      i {
        color: #eee;
      }
      &.is-active {
        background-color: #1890ff !important;
        color: #fff !important;
        i {
          color: #fff;
        }
      }
    }
  }
  .el-submenu {
    &__title {
      padding-left: 16px !important;
      padding-right: 16px;
      height: 40px;
      line-height: 40px;
      color: #eee !important;
      i {
        color: #eee;
      }
    }
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      color: #eee !important;
      > span {
        padding-left: 4px;
      }
    }
    &.is-opened {
      .el-submenu__title {
        background-color: #000d17 !important;
      }
      .el-menu-item {
        background-color: #000d17 !important;
        &.is-active {
          background-color: #1890ff !important;
          color: #fff !important;
          i {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
