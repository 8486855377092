export default [
  {
    path: '/user',
    name: 'Account',
    component: () => import('@/views/permission/account')
  },
  {
    path: '/role',
    name: 'Role',
    component: () => import('@/views/permission/role')
  },
];
