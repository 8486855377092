import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import Qs from 'qs';
import NProgress from 'nprogress';
import router from '@/router/index';
import JsonBigInt from 'json-bigint';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 50000,                  // 请求超时时间
  transformResponse: data => {
    return JsonBigInt({ storeAsString: true }).parse(data);
  }
});

async function transformConfig (config) {
  if (store.getters.token) {
    config.headers['Authorization'] = store.getters.token;
  }

  config.transformRequest = function (data) {
    if (config.headers['Content-Type'] === 'application/json') {
      return JSON.stringify(data);
    }
    if (data && data.isUploadFile) {
      let params = new FormData();
      for (var key in data) {
        if (key !== 'isUploadFile' && key !== 'files') {
          params.append(key, data[key]);
        }
        if (key === 'files') {
          if (data[key] && data[key].length > 0) {
            data[key].map(item => {
              params.append('file', item);
            });
          }
        }
      }
      return params;
    } else {
      return Qs.stringify(data);
    }
  };
  return config;
}

// request拦截器
service.interceptors.request.use(config => {
  return transformConfig(config);
}, error => {
  return Promise.reject(error);
});

// 刷新token
function getRefresh () {
  return service.get('/token/refresh', {
    params: {
      token_refresh: true
    }
  }).then(function (response) {
    if (response.headers.authorization) {
      store.dispatch('setToken', response.headers.authorization);
    }
  });
}

// response拦截器
service.interceptors.response.use(response => {
  let data = response.data;
  if (response.config.responseType === 'blob') {
    return data;
  }
  if (data.code === 0) {
    return data.data || '';
  } else {
    Message.closeAll();
    if (data.code === 110) {
      store.dispatch('unsetToken').then(() => {
        Message({
          message: data.msg, type: 'error', duration: 3000, onClose () {
            if (location.url.indexOf('login') === -1) {
              router.push({ path: `/login` });
            }
          }
        });
      });
    } else if (data.code === 100 && data.msg === "Illegal request") {
      return {
        code: 1
      };
    } else {
      if (!response.config.customError) {
        Message({ message: data.msg, type: 'error', duration: 3000 });
      }
    }
    return Promise.reject(data);
  }
  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
   */
}, error => {
  const loginPath = process.env.PREFIXES ? `/${process.env.PREFIXES}/login` : '/login';
  Message.closeAll();
  var msg = '';
  // 如果token已经过期，则刷新token
  if (error.response) {
    if (error.response && error.response.status === 401 && error.response.data.msg === "token过期或已失效") {
      // router.push({ path: loginPath });
      msg = '你已被登出，请重新登录';
      setTimeout(() => {
        const token = store.getters.token || '';
        let url = process.env.VUE_APP_IFRAME_URL;
        const loginUrl = `${url}/logout?token=${token}&client_id=43d93dab7ebef303`;
        window.location.replace(loginUrl);
        NProgress.done();
      }, 2000);
    } else if (error.response.data.error.message === 'The token has been blacklisted' || error.response.data.error.message === 'Token has expired') {
      msg = '当前页面已过期，请刷新';
    } else if (error.response && error.response.status === 403 && error.response.data.error.message === 'access denied, username password are not match') {
      msg = '用户名或密码错误';
    } else if (error.response && error.response.status === 403 && error.response.data.error.message === 'Token Signature could not be verified.') {
      router.push({ path: loginPath });
      NProgress.done();
    } else {
      msg = error && error.response ? error.response.data.error.message : '';
    }
  } else if (error.message) {
    msg = error.message;
  }
  if (!msg) {
    msg = '系统繁忙，请重试';
  }
  if (msg !== 'cancel') {
    Message({ message: msg, type: 'error', duration: 3 * 1000 });
  }

  return Promise.reject(error);
});

export default service;
