<template>
  <div>
    <el-menu-item v-if="!menu.children" :index="menu.menuPath">
      <i :class="menu.resourceIcon" class="iconfont" style="margin-right: 12px" />
      <span slot="title">{{ menu.resourceName }}</span>
    </el-menu-item>
    <el-submenu v-else :index="menu.resourceName">
      <template slot="title">
        <i :class="menu.resourceIcon" class="iconfont" style="margin-right: 12px" />
        <span>{{ menu.resourceName }}</span>
      </template>
      <div v-for="sub in menu.children" :key="sub.resourceName">
        <el-menu-item
          v-if="!sub.children" :index="sub.menuPath"
        >
          <span>{{ sub.resourceName }}</span>
        </el-menu-item>
        <SidebarSub v-else :menu="sub" />
      </div>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SidebarSub',
  props: {
    menu: {
      type: Object,
      default: () => ([])
    }
  }
};
</script>
