import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import getters from './getters';
import role from './modules/role';
import menu from './modules/menu';
import tenant from './modules/tenant';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    role,
    menu,
    tenant,
  },
  getters
});

export default store;
