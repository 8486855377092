<template>
  <div class="app-container">
    <div class="app-side">
      <div class="app-logo">
        <img alt="Vue logo" :src="logoImg" @click="linkHome">
        <div class="app-name">
          账号管理系统
        </div>
      </div>
      <div class="app-menus">
        <Sidebar :menus="menuData" />
      </div>
    </div>

    <div class="app-main">
      <div class="app-toolbar">
        <div class="back-btn" @click="goBack">
          <i class="iconfont icon-fanhui-01" />返回
        </div>
        <span class="split-line" />
        <el-breadcrumb separator="/" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <span class="text" @click="linkHome">首页</span>
          </el-breadcrumb-item>
          <template v-if="menuPaths.length > 0">
            <el-breadcrumb-item v-for="(menu, index) in menuPaths" :key="index">
              <span :class="[menu.disable ? 'invalid' : 'text']" @click="handleClickBreadcrumb(menu)">{{ menu.label }}</span>
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <div class="option-btns">
          <el-dropdown @command="handleLogout">
            <span class="el-dropdown-link">
              <el-avatar :size="24" :src="userInfo.avatar" />
              <span class="username">{{ nick_name || user_name }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="password">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                退出
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="app-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Sidebar from '@/components/sidebar';
import { useDialog } from "@shopex/finder";
import { getEnterpInfo } from '@/api/setting';
// import { logout } from '@/api/auth';
export default {
  components: {
    Sidebar,
  },
  data () {
    return {
      menuData: [],
      userInfo: {
        avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
      },
      menuPaths: [],
      curPath: '',
      logoImg: require('@/assets/logo.png'),
    };
  },
  computed: {
    ...mapState({
      nick_name: state => state.user.nickname,
      user_name: state => state.user.username,
      token: state => state.user.token,
    })
  },
  mounted () {
    getEnterpInfo().then(res => {
      if (res.entLogo) {
        this.logoImg = res.entLogo;
      }
    });
  },
  methods: {
    async handleLogout (command) {
      if (command === "password") {
        useDialog(this, {
          title: "修改密码",
          data: [this.token],
          component: () => import("./component/update-password-dialog"),
          actions: [
            { label: "取消", action: "close" },
            { label: "保存", action: "save", type: "primary" },
          ]
        });
      } else {
        // await logout();
        // this.$router.push("/login");
        const token = this.token;
        let url = process.env.VUE_APP_IFRAME_URL;
        const loginUrl = `${url}/logout?token=${token}&client_id=43d93dab7ebef303`;
        window.location.replace(loginUrl);
      }
    },
    goBack () {
      this.$router.go(-1);
    },
    linkHome () {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    },
    handleClickBreadcrumb (item) {
      if (!item.disable && item.path !== this.$route.path) {
        this.$router.push({ path: item.path });
      }
    }
  }
};
</script>
