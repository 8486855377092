import {getMenuList, getPermission, getRoleDetail} from "@/api/role";

export default {
  namespaced: true,
  state: {
    menuList: [],
    roleDetail: {},
    permissionList: [],
  },
  mutations: {
    setMenuList: (state, payload) => state.menuList = payload,
    setRoleDetail: (state, payload) => state.roleDetail = payload,
    setPermissionList: (state, payload) => state.permissionList = payload,
  },
  actions: {
    async getMenuList ({ commit }) {
      const res = await getMenuList();
      commit('setMenuList', res);
    },
    async getRoleDetail ({ commit }, id) {
      const res = await getRoleDetail(id);
      commit('setRoleDetail', res);
    },
    async getPermissionList ({commit}) {
      const res = await getPermission();
      commit('setPermissionList', res);
    }
  }
};
